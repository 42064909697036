import { RBTagQueryDTO, ReportMetadataDTO, TagMatch } from '@/api/dto/report';
import { UserStatuses } from '@/api/dto/user';
import { fetchReportList } from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { SortDirection } from '@/helpers/data-query';
import { FetchStates } from '@/helpers/fetch-states';
import { OrganizationsModule } from '@/store/organizations';
import { computed, ref, watch } from 'vue';

export function useReportList() {
  const reports = ref<ReportMetadataDTO[]>([]);
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const returnCount = ref(0);
  const total = ref(0);
  const page = ref(1);
  const pageSize = ref(100);
  const sortDir = ref<SortDirection>(SortDirection.DESC);
  const sortBy = ref<keyof ReportMetadataDTO>('date_created');
  const orgIds = computed(() => OrganizationsModule.activeOrgIds);
  const searchStr = ref<string>('');
  const searchStatus = ref<boolean | undefined>(undefined);
  const searchIntegration = ref<string | undefined>('');
  const tagQueries = ref<RBTagQueryDTO[]>([]);
  const totalTagQueries = computed(() => tagQueries.value.length);
  const tagMatch = ref<TagMatch>(TagMatch.some);

  const fetchReports = async () => {
    if (orgIds.value.length < 1) {
      reports.value = [];
      returnCount.value = 0;
      total.value = 0;
      page.value = 1;

      return;
    }

    fetchState.value = FetchStates.FETCHING;
    try {
      const data = await fetchReportList({
        org_ids: orgIds.value,
        tags: tagQueries.value,
        tagMatch: tagMatch.value,
        sort_by: sortBy.value,
        sort_dir: sortDir.value,
        active: searchStatus.value,
        search_str: searchStr.value,
        integration: searchIntegration.value,
        limit: pageSize.value,
        offset: (page.value - 1) * pageSize.value,
      });

      reports.value = data.reports;
      total.value = data.total;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error fetching reports.');
      console.error(error);
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  const onSortChanged = ({
    order,
    prop,
  }: {
    order: 'ascending' | 'descending';
    prop: keyof ReportMetadataDTO;
    column: any;
  }) => {
    page.value = 1;
    sortBy.value = prop;

    if (order === 'descending') sortDir.value = SortDirection.DESC;
    else sortDir.value = SortDirection.ASC;
  };

  const statusFilterHandler = (statusRadio: UserStatuses) => {
    switch (statusRadio) {
      case UserStatuses.ALL:
        searchStatus.value = undefined;
        break;
      case UserStatuses.ACTIVE:
        searchStatus.value = true;
        break;
      case UserStatuses.DELETED:
        searchStatus.value = false;
        break;
    }
  };

  const searchChangeHandler = (search: string) => {
    searchStr.value = search;
  };

  const integrationChangeHandler = (integration: string) => {
    if (integration === 'all') {
      searchIntegration.value = undefined;
    } else {
      searchIntegration.value = integration;
    }
  };

  const tagChangeHandler = (tags: RBTagQueryDTO[]) => {
    tagQueries.value = tags;
  };

  const tagMatchChangeHandler = (match: TagMatch) => {
    tagMatch.value = match;
  };

  watch(page, fetchReports);
  watch(pageSize, fetchReports);
  watch(sortBy, fetchReports);
  watch(sortDir, fetchReports);
  watch(orgIds, fetchReports);
  watch(searchStatus, fetchReports);
  watch(searchStr, fetchReports);
  watch(searchIntegration, fetchReports);
  watch(totalTagQueries, fetchReports);
  watch(tagMatch, fetchReports);

  fetchReports();

  return {
    reports,
    fetchState,
    returnCount,
    total,
    page,
    pageSize,
    sortDir,
    sortBy,
    orgIds,
    searchStr,
    searchStatus,
    tagQueries,
    totalTagQueries,
    tagMatch,
    fetchReports,
    onSortChanged,
    statusFilterHandler,
    searchChangeHandler,
    integrationChangeHandler,
    tagChangeHandler,
    tagMatchChangeHandler,
  };
}
