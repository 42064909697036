
import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useReportList } from '@/composables/useReportList';
import { FetchStates } from '@/helpers/fetch-states';
import { useOrgManager } from '@/composables/useOrgManager';
import {
  baseURL as rbBaseUrl,
  requestReportExport,
  downloadExport,
} from '@/api/report-builder';
import { getIntegrationName } from '@/api/integration-name-map';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';
import { UserPrefs } from '@/api/dto/user-prefs';
import { RBTagQueryDTO, TagMatch } from '@/api/dto/report';
import SearchBar from '../search/SearchBar.vue';
import { UserStatuses } from '@/api/dto/user';
import { useAuth } from '@/composables/useAuth';

export default defineComponent({
  name: 'ReportsList',
  components: {
    SearchBar,
  },
  setup() {
    const { orgMap } = useOrgManager();

    const userPrefs: Ref<UserPrefs | null> = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    const downloadReport = async (reportId: string) => {
      const exportReq = await requestReportExport(reportId);
      downloadExport(exportReq.nonce);
    };

    const { isVAR, isSuperAdmin } = useAuth();

    return {
      ...useReportList(),
      orgMap,
      userPrefs,
      rbBaseUrl,
      FetchStates,
      getIntegrationName,
      convertDateString,
      downloadReport,
      isVAR,
      isSuperAdmin,
    };
  },
  methods: {
    atSearchChange(val: string) {
      this.searchChangeHandler(val);
    },
    atStatusChange(val: UserStatuses) {
      this.statusFilterHandler(val);
    },
    atIntegrationChange(val: string) {
      this.integrationChangeHandler(val);
    },
    atTagQueryChange(val: RBTagQueryDTO[]) {
      this.tagChangeHandler(val);
    },
    atTagMatchChange(val: TagMatch) {
      this.tagMatchChangeHandler(val);
    },
  },
});
